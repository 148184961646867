<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <datatable-glossary-search></datatable-glossary-search>
        </v-col>
        <v-col cols="12">
          <datatable-categories-search></datatable-categories-search>
        </v-col>
        <v-col cols="12">
          <datatable-languages-search></datatable-languages-search>
        </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DatatableCategoriesSearch from "./Widgets/DatatableCategoriesSearch.vue";
import DatatableGlossarySearch from "./Widgets/DatatableGlossarySearch.vue";
import DatatableLanguagesSearch from "./Widgets/DatatableLanguagesSearch.vue";
export default {
  name: "datatables",
  components: {
    DatatableCategoriesSearch,
    DatatableGlossarySearch,
    DatatableLanguagesSearch
  },
};
</script>
