<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">Glossar</h5>
        <p class="text-sm text-body mb-0">Suche in Glossar nach: <span class="text-success">{{ $route.query.searchTerm }}</span> </p>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="glossary"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          
          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-sm font-weight-normal text-body">
                {{ item.name }}
              </span>
            </div>
          </template>

          <template v-slot:item.email="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.email }}
            </span>
          </template>

          <template v-slot:item.age="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.age }}
            </span>
          </template>

          <template v-slot:item.salary="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.salary }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-ruler-pencil</v-icon>
            </v-btn>

            <v-btn
              @click="deleteItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-fat-remove text-lg</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import users from "./Users";

export default {
  name: "paginated-tables",
  mounted() {
    this.$store.dispatch("getGlossary");
    this.$store.dispatch("getLanguages");
    this.$store.dispatch("getCategory");
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      editedIndex: -1,
      editedItem: {
        language: {},
        category: {},
      },
      defaultItem: {
        language: {},
        category: {},
      },
      headers: [
        {
          text: "Begriff",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "expression",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Erklärung",
          value: "explanation",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Erläuterung",
          value: "explanationDetail",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Kategorie",
          value: "category.title",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Sprache",
          value: "language.title",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.glossary.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.glossary.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      // this.glossary.splice(this.editedIndex, 1);
      this.closeDelete();
      this.$store.dispatch('deleteGlossary', this.editedItem.id)
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.glossary[this.editedIndex], this.editedItem);
        this.$store.dispatch("editGlossary", this.editedItem);
      } else {
        this.$store.dispatch("postGlossary", this.editedItem);
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    search(){
      return this.$route.query.searchTerm
    },
    glossary() {
      if (this.$store.getters.glossary.data) {
        return this.$store.getters.glossary.data;
      } else {
        return [];
      }
    },
    languages() {
      if (this.$store.getters.languages.data) {
        return this.$store.getters.languages.data;
      } else {
        return [];
      }
    },
    categories() {
      if (this.$store.getters.categories.data) {
        return this.$store.getters.categories.data;
      } else {
        return [];
      }
    },
    formTitle() {
      return this.editedIndex == -1 ? "Neuer Begriff" : "Begriff editieren";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
